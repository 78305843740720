var BS = (function (globalObj, $) {
  var alerts   = globalObj.alerts = globalObj.alerts || {};
  var buttons  = globalObj.buttons = globalObj.buttons || {};
  var modals   = globalObj.modals = globalObj.modals || {};
  var popovers = globalObj.popovers = globalObj.popovers || {};
  var sliders   = globalObj.sliders = globalObj.sliders || {};

  if (typeof jQuery !== 'function') {
    alert('This Library requires Jquery');
    return false;
  }

  /**
    * Button : Busy
    * @constructor
    * @param {string} targetObj  - Button you want to busy
    * @param {string} icon  - right now it just accepts a FontAwesome icon
    * @param {string} msg  - e.g 'Processing...'
  */
  buttons.busy = function (targetObj, icon, msg) {
    var button = {};

    button['btn'] = targetObj;
    button['txt'] = targetObj.html();

    targetObj.html('<i class="fa ' + icon + '"></i> ' + msg);
    targetObj.prop('disabled', true);
    return button;
  };

  buttons.reset = function (targetObj, msg) {
    targetObj.html(msg);
    targetObj.prop('disabled', false);
    return targetObj;
  };

  modals.default = function (dismissOn, classes, title, body, footer) {
    var dismiss = dismissOn ? '<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>' : ' ';
    var MODAL_TEMPLATE = '<div class="modal ' + classes + ' fade in" style="display:block;" tabindex="-1" role="dialog"><div class="modal-dialog" role="document"><div class="modal-content"><div class="modal-header">' + dismiss + title + '</div><div class="modal-body">' + body + '</div><div class="modal-footer">' + footer + '</div></div></div></div>';

    $('body').addClass('model-open').attr('style', 'padding-right:15px;');
    $('body').append('<div class="modal-backdrop fade in"></div>');
    $('.modal-backdrop').before(MODAL_TEMPLATE);

    return $(MODAL_TEMPLATE);
  };

  popovers.error = function (msg, classes, targetObj) {
    var popup              = $('<div>', { 'class': 'popover fade top in ' + classes });
    var arrow              = $('<div>', { 'class': 'arrow' });
    var popupInner         = $('<div>', { 'class': 'popover-inner' });
    var popupContent       = $('<div>', { 'class': 'popover-content' });

    popup.append(arrow);
    popup.append(popupInner);
    popupInner.append(popupContent);
    popupContent.html(msg);

    targetObj.parent().append(popup);

    targetObj.blur();
    targetObj.value = '';

    setTimeout(function(){
      popup.fadeOut(500, function(){
        $(this).remove();
      });
    }, 3000);
  };

  sliders.initAutoScroll = function (obj, speed) {
    var sliderContainer = obj
    ,pos   = 0
    ,count = 0;

    if ( typeof sliderContainer === 'undefined' ) {
      return false;
    }

    window.setInterval(function() {

      if ( pos === ( sliderContainer[0].scrollWidth - sliderContainer.width() ) ) {
        count = 0;
        pos = 0;
        sliderContainer.animate({scrollLeft: 0}, speed);
      } else {
        sliderContainer.animate({scrollLeft: count += 2}, speed);
      }

      pos = sliderContainer.scrollLeft();

    }, speed);

    return sliderContainer;
  };

  return globalObj;
}(BS || {}, jQuery));

(function(globalObj,$) {
  var documentSelection = $(document)
  ,BS = globalObj.BS
  ,sliders = BS.sliders
  ,guestCards = $('.card__guest')
  ,sliderContainer = $('.slider__overscroll');

  setTimeout(function () {
    if (sliderContainer.length > 0) {
      sliders.initAutoScroll(sliderContainer, 50);
    }
  }, 1000);

  // Activate Cards
  guestCards.each(function () {
    var $this = $(this)
    ,boxGuest = $this.find('.img__guest')
    ,boxBook = $this.find('.img__book')
    ,srcGuest = boxGuest.data('src')
    ,srcBook = boxBook.data('src');

    boxGuest.css('background-image', 'url(' + srcGuest + ')');
    boxBook.css('background-image', 'url(' + srcBook + ')');
  });

}(this,jQuery));
